import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, AfterViewInit  } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/appconstants';
import { TableBtn, TableColumn } from 'src/app/interfaces';
import { TableProperties } from 'src/app/model/table/table-properties';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrl: './mat-table.component.css'
})
export class MatTableComponent implements OnChanges, AfterViewInit {  
  @Input() columns: TableColumn[] = [];
  @Input() searchRequired: boolean = true;
  @Input() paginationRequired: boolean = false;
  @Input() actionsColumn: boolean = false;
  @Input() totalCount: any;
  @Input() buttons: TableBtn[] = [];
  @Input() data: any[] = [];
  @Input() filter: boolean = false;
  @Input() filterPlaceholder: string = 'Filter';
  @Input() footer?: string = "" || undefined;
  @Input() pagination: number[] = [];
  @Input() pageSize!: number;
  @Input() defaultSortColumn: any;
  @Input() defaultSortOrder: any;
  @Input() tableMinWidth: number = 500;
  @Input() loading: boolean = false;
  @Output() tableChange = new EventEmitter<TableProperties>();
  @Output() cellClick = new EventEmitter<any>();

  dataSource!: MatTableDataSource<any>;
  displayedColumns!: string[];
  tableProperties: TableProperties = new TableProperties();
  searchText = "";

  @ViewChild(MatPaginator, {static: true}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data){
      if(changes['data']){
        this.dataSource =  new MatTableDataSource(this.data);
        this.displayedColumns = [...this.columns.filter(c => !c.hide).map(c => c.columnDef)];
        if (this.actionsColumn) this.displayedColumns = [...this.displayedColumns, 'actions'];
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.sort.sortChange.subscribe(() => {
        if (this.paginator)
          {
            this.paginator.pageIndex = AppConstants.DEFAULTPAGINDEX;
          }        
      });
    }    
  }

  onSortChange(event: any) {
    this.tableProperties.sortOrder = event.direction;
    this.tableProperties.sortColumn = event.active;
    this.tableProperties.pageIndex =  AppConstants.DEFAULTPAGINDEX;
    this.tableProperties.pageSize = AppConstants.DEFAULTPAGESIZE;
    this.tableChange.emit(this.tableProperties);
  }

  onPaginationChange(event: any) {
    this.tableProperties.sortOrder = this.sort.direction;
    this.tableProperties.sortColumn = this.sort.active;
    this.tableProperties.pageIndex = event.pageIndex ?? AppConstants.DEFAULTPAGINDEX;
    this.tableProperties.pageSize = event.pageSize ?? AppConstants.DEFAULTPAGESIZE;
    this.tableChange.emit(this.tableProperties);
  }

  onCellClick(row: any, action: any) {
    this.cellClick.emit({ row: row, action: action});
  }

  onSearch() {
    setTimeout(() => {
      this.tableProperties.sortOrder = this.sort.direction;
      this.tableProperties.sortColumn = this.sort.active;
      this.tableProperties.pageIndex = AppConstants.DEFAULTPAGINDEX;
      this.tableProperties.pageSize = AppConstants.DEFAULTPAGESIZE;
      this.tableProperties.searchText = this.searchText;
      this.tableChange.emit(this.tableProperties);
    }, 2000);        
  }
}
