import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient ) { 
    
  }

  getEmployerList(searchText: string, sortColumn: string, sortOrder: string, pageNumber: number, pageSize: number): Observable<any> {

    var result = this.http.get<any>(`${this.apiUrl}/admin/employers`, { params: new HttpParams()
        .set("searchText", searchText.toString())
        .set("sortColumn", sortColumn)
        .set("sortDirection", sortOrder)
        .set("pageIndex", pageNumber.toString())
        .set("pageSize", pageSize.toString())
      });
    return result;
  }
}
