<div *ngIf="searchRequired" class="table-head-wrp d-flex justify-content-between mt-4 mb-3 align-items-center">
    <h6>All <span>({{ totalCount }})</span></h6>
    <div class="search-filterwrp d-flex">
        <div class="srch-bx ">
            <input [(ngModel)]="searchText" (input)="onSearch()" class="form-control" placeholder="Search" type="text">
            <img src="assets/icons/search.svg" alt="">
        </div>
    </div>
</div>
<div class="card">
    <div class="responsive-table th-sticky">
        <div class="">
            <table mat-table [dataSource]="dataSource" matSort matSortDisableClear matSortActive="id" matSortDirection="asc" (matSortChange)="onSortChange($event)">
        
                <ng-container *ngFor="let column of columns">
                    <ng-container matColumnDef="{{column.columnDef}}">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header }}</th>
                        <td mat-cell *matCellDef="let row">
                            <div [ngSwitch]="column.type">
                                <a *ngSwitchCase="'link'" class="{{ column.class }}" (click)="onCellClick(row, 'view')">{{ column.cell(row) }}</a>                    
                                <div *ngSwitchCase="'text'" [innerHTML]="column.cell(row)"></div>
                                <div *ngSwitchCase="'image'"><img class="{{ column.class }}" src="{{ column.cell(row) }}"></div>
                            </div>                                                
                        </td>
                    </ng-container>
                </ng-container>
                <ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let row">
                            <div>
                                <button mat-button [matMenuTriggerFor]="menu"><span><img src="assets/icons/icon-more.svg" alt=""></span></button>
                                <mat-menu #menu="matMenu" yPosition="below">
                                    <button mat-menu-item (click)="onCellClick(row, 'view')">View User</button>
                                    <button mat-menu-item (click)="onCellClick(row, 'disable')">Disable User</button>
                                </mat-menu>    
                            </div>
                        </td>
                    </ng-container>
                </ng-container>
        
                <!-- Disclaimer column - with nullable approach -->
                <ng-container matColumnDef="disclaimer" *ngIf="footer">
                    <td mat-footer-cell *matFooterCellDef colspan="100%">
                        <strong>{{footer}}</strong>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
                <ng-container *ngIf="footer"> <!-- Make footer nullable -->
                    <tr mat-footer-row *matFooterRowDef="['disclaimer']" class="example-second-footer-row"></tr>
                </ng-container>
            </table>
        
            <mat-paginator *ngIf="dataSource && paginationRequired"
                class="" 
                [length]="totalCount"
                [pageSizeOptions]="pagination" 
                [pageSize]="pageSize"
                (page)="onPaginationChange($event)">
            </mat-paginator>
        </div>
    </div>
</div>
